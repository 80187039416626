<template>
  <div class="company-box">
    <div class="left">
      <img v-if="currentUserInfo.headUrl" :src="currentUserInfo.headUrl" class="avatar">
      <el-avatar v-else :size="48" icon="el-icon-user-solid"></el-avatar>
      <div class="name-box">
        <div class="name">您好，{{ currentUserInfo.nickName || currentUserInfo.name }}</div>
        <div class="welcome">欢迎来到小紫平台</div>
      </div>
    </div>
    <div class="right">
      <div @click="showVisible" class="button button-1">
        <img :src="require('@/assets/imgs/workbench/ic_switch.svg')" />
        切换
      </div>
    </div>
    <el-dialog title="切换" :visible.sync="visible" width="500px">
      <company-list :companyLists='companyLists' @refresh="refresh" />
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { createTenant } from '@/api/company';
import switchCompany from '@/utils/switchCompany';
import CompanyList from '@/components/common/companyList/index.vue';
export default {
  components: {
    CompanyList,
  },
  props: {
    currentUserInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      visible: false,
      companyLists: [],
      statusList: [
        {
          status: '未认证',
          statusCode: '1',
          img: require('@/assets/ic_weirenzheng.svg'),
        },
        {
          status: '认证中',
          statusCode: '2',
          img: require('@/assets/ic_renzhengzhong.svg'),
        },
        {
          status: '已认证',
          statusCode: '3',
          img: require('@/assets/ic_yirenzheng.svg'),
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    async save (type) {
      if (!this.name) return;
      const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));

      const list = await createTenant({
        id: employeeMsg.id,
        tenantNames: [this.name],
      });

      this.$message.success('企业创建成功');
      this.name = '';

      await switchCompany(list.body[0].tenantId);
      this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));
      this.$tabs.items = [];
      this.$tabs.close({
        to: '/workbench',
        refresh: true,
      });
      window.location.reload();

      if (type === 2) {
        setTimeout(() => {
          this.$router.push({
            path: '/workbench/identification',
          });
        }, 2000);
      }
    },
    showVisible () {
      this.companyLists = JSON.parse(this.$local.get('ownerList')) || [];
      this.visible = true;
    },
    refresh () {
      this.visible = false;
      // this.$tabs.refreshAll(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.company-box {
  font-size: 14px;
  color: #1F2733;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .left {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    .name-box {
      margin-left: 10px;
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: bold;
        padding-right: 15px;
      }
      .welcome {
        color: #929AA6;
      }
    }
  }
  .right {
    display: inline-flex;
    align-items: center;
    .button {
      padding: 6px 13px;
      border-radius: 4px;
      cursor: pointer;
      > img {
        width: 14px;
        height: 14px;
      }
    }
    .button-1 {
      color: #237FFA;
      background: #E2F3FE;
      margin-right: 10px;
    }
  }
}
</style>
