<template>
  <div class="mineMessage-box">
    <div class="header">
      <b>消息中心</b>
      <div @click="toMessages" class="more">
        <span>更多</span>
        <img :src="require('@/assets/imgs/workbench/icon_right.svg')">
      </div>
    </div>
    <div class="class">
      <div @click="activeTab = 'all'" :class="{'selected': activeTab === 'all'}">消息</div>
      <div @click="activeTab = 'mine'" :class="{'selected': activeTab === 'mine'}">@我的</div>
    </div>
    <div v-if="list && list.length" class="list">
      <div v-for="(item, index) in list" :key="'msg-'+index" @click="scan(index, item)" class="item">
        <div class="title-time">
          <div class="title"><span v-if="!item.readFlag" class="readFlag"></span>{{ item.businessTypeName }}</div>
          <div class="time">{{ item.createTime ? formatDate(item.createTime, 'YYYY-MM-DD HH:mm:ss') : '' }}</div>
        </div>
        <div class="content">{{ item.remark }}</div>
      </div>
    </div>
    <Empty v-else />
    <!-- 消息详情 -->
    <el-dialog :title="currentItem.businessTypeName" :visible.sync="dialogVisible" width="70vw">
      <div style="height: 50vh; overflow-y: scroll;">{{ currentItem.remark }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as Moment from 'dayjs';
import { queryPage, updateReadFlag } from '@/api/messages';
import Empty from '@/components/empty.vue';
export default {
  components: {
    Empty,
  },
  watch: {
    activeTab () {
      this.init();
    },
  },
  data () {
    return {
      activeTab: 'all',
      pageNum: 1,
      pageSize: 5,
      currentItem: {},
      dialogVisible: false,
      list: [],
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    toMessages () {
      this.$router.push({
        name: 'MESSAGES',
      });
    },
    async scan (i, item) {
      await updateReadFlag({ id: item.id });
      this.list[i].readFlag = 1;
      this.currentItem = item;
      this.dialogVisible = true;
    },
    async queryPageList () {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      if (this.activeTab === 'mine') {
        const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
        params.remindUserId = employeeMsg.id;
      }
      const data = await queryPage(params);
      if (data.body.list) {
        if (data.body.list.length === params.pageSize) {
          this.isLastPage = false;
        } else {
          this.isLastPage = true;
        }

        if (this.pageNum === 1) {
          this.list = data.body.list;
        } else {
          this.list = this.list.concat(data.body.list);
        }
      }
    },
    init () {
      this.pageNum = 1;
      this.list = [];
      this.queryPageList();
    },
  },
};
</script>
<style lang="scss" scoped>
.mineMessage-box {
  font-size: 14px;
  color: #1F2733;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 0 20px;
  margin-bottom: 15px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    .more {
      font-size: 0;
      font-weight: 400;
      color: #929AA6;
      padding: 20px 0;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      > span {
        font-size: 14px;
      }
      > img {
        width: 20px;
        height: 24px;
        margin-left: 10px;
      }
    }
  }
  .class {
    border-radius: 4px;
    background: #F2F4F7;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    > div {
      display: inline-block;
      color: #5F6A7A;
      padding: 6px 20px;
    }
    .selected {
      color: #237FFA;
      background-color: #E2F3FE;
    }
  }
  .list {
    min-height: 250px;
    padding: 20px 0;
    .item {
      padding-bottom: 20px;
      cursor: pointer;
      .title-time {
        display: flex;
        justify-content: space-between;
        .title {
          padding-left: 15px;
          font-weight: bold;
          position: relative;
          .readFlag {
            position: absolute;
            left: 0;
            top: 7px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #237FFA;
          }
        }
        .time {
          font-size: 12px;
          font-weight: 400;
          color: #929AA6;
        }
      }
      .content {
        padding-top: 5px;
        padding-left: 15px;
        font-size: 12px;
        font-weight: 400;
        color: #929AA6;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
